/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, navigate } from "gatsby"
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ThemeProvider,
} from "@material-ui/core"
import Icon from "@material-ui/core/Icon"
import theme from "./theme"
import ResponsiveDrawer from "./ResponsiveDrawer"
import "./layout.css"
import "@material-ui/icons/Menu"
import { Helmet } from "react-helmet"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          menuLinks {
            name
            link
            icon
          }
        }
      }
    }
  `)
  const myLinks = (
    <List>
      {data.site.siteMetadata.menuLinks.map((link, index) => (
        <ListItem button key={link.name} onClick={() => navigate(link.link)}>
          <ListItemIcon>{link.icon && <Icon>{link.icon}</Icon>}</ListItemIcon>
          <ListItemText primary={link.name} />
        </ListItem>
      ))}
      <ListItem button key="tools" onClick={()=>window.location.href="/misc/"}>
        <ListItemIcon><Icon>build</Icon></ListItemIcon>
        <ListItemText primary="Tools"></ListItemText>
      </ListItem>
    </List>
  )

  return (
    <>
      <Helmet>
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/icon?family=Material+Icons"
        />
      </Helmet>
      {/* <Header
        siteTitle={data.site.siteMetadata.title}
        themeColor={theme.palette.primary.main}
        links={data.site.siteMetadata.menuLinks}
      /> */}
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 960,
          padding: `0 1.0875rem 1.45rem`,
        }}
      >
        <ThemeProvider theme={theme}>
          <ResponsiveDrawer
            links={myLinks}
            title={data.site.siteMetadata.title}
          >
            <main>{children}</main>
            <footer>
            © {new Date().getFullYear()}, Built with
            {` `}
            <a href="https://www.gatsbyjs.org">Gatsby</a>
          </footer>
          </ResponsiveDrawer>
        </ThemeProvider>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
